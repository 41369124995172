.root{
  background: rgb(249, 250, 251);
  height: 100vh;
  display: grid;
  place-items: center;
}
.heading{
  text-align: center;
}
.card-content{
  max-width: 480px; 
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
}
@media screen and (max-width: 500px) {
  .card-content{
    border-radius: 0px;
    width: 100vw;
    height:100vh;
    justify-content: flex-start;
  }
  .container{
    padding: 0px !important;
  }
  .footer-text{
    margin-top: 5px !important;;
  }
}
.sub-content{
  padding: 25px;
}